<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            sort-by="code"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Variant Resep</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="secondary" dark class="mb-2 mr-2" @click="refreshData" >
                  Refresh
                </v-btn>
                <v-btn color="primary" dark class="mb-2" @click="addNewItem" v-if="$store.state.currentPagePriv.create">
                  New Variant
                </v-btn>
                <v-dialog v-model="dialog" v-if="dialog" max-width="800px" scrollable persistent>
                <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                      New Variant
                    </v-btn>
                </template> -->
                <v-card>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation class="compact-form">
                        <v-row>
                          <v-col cols="12" sm="3" md="3">
                              <span>Code</span>
                              <v-text-field v-model="editedItem.code" filled rounded dense hide-details="auto" @keydown.space.prevent
                                :readonly="editedIndex>-1" :rules="reqRule"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                              <span>Initial</span>
                              <v-text-field v-model="editedItem.initial" filled rounded dense hide-details="auto" @keydown.space.prevent
                                :rules="reqRule"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="6">
                              <span>Nama Variant</span>
                              <v-text-field v-model="editedItem.name" filled rounded dense hide-details="auto" :rules="reqRule"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="6">
                              <span>Volume</span>
                              <v-select :items="bottleSize" item-text="description" item-value="code" v-model="editedItem.size" filled rounded dense hide-details="auto" :rules="reqRule"></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                              <span>Actual Volume</span>
                              <v-text-field type="number" suffix="ml" v-model="editedItem.actual_volume" filled rounded dense hide-details="auto" @wheel="$event.target.blur()"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="6">
                              <span>Mesin</span>
                              <v-select :items="machines" item-text="description" item-value="code" v-model="editedItem.machine_code" filled rounded dense hide-details="auto" :rules="reqRule">
                                <template slot="item" slot-scope="data">
                                  <span class="compact-form">
                                  {{ data.item.description }} - {{ data.item.misc_field }}
                                  </span>
                                </template>                                
                              </v-select>
                          </v-col>
                          <v-col cols="12" sm="4" md="6">
                              <span>Minimum Stock</span>
                              <v-text-field v-model="editedItem.qty_min" filled rounded dense hide-details="auto"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="6" v-if="profile.role_code=='SUP'">
                              <span>Budget</span>
                              <v-text-field v-model="editedItem.cost_budget" prefix="Rp" filled rounded dense hide-details="auto"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                              <span>Description</span>
                              <v-textarea v-model="editedItem.description" filled rounded dense hide-details="auto" rows="2"></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">Bahan Baku</v-col>
                          <v-col cols="3">Persentase</v-col>
                          <!-- <v-col cols="3">UoM</v-col> -->
                          <v-col cols="2"></v-col>
                        </v-row>
                        <v-row v-for="(obj, idx) in editedItem.details" :key="idx">
                          <v-col cols="4">
                            <v-autocomplete filled rounded dense :items="rawmaterials" item-text="description" item-value="code" v v-model="obj.raw_material_code" hide-details="auto" @change="fillUom(obj)" autocomplete="off" :rules="reqRule" auto-select-first></v-autocomplete>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field v-model="obj.quantity" filled rounded dense hide-details="auto" :rules="reqRule" suffix="%"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="3" :v-show="false">
                            <v-select readonly filled rounded dense :items="uom" v-model="obj.uom" hide-details="auto"></v-select>
                          </v-col> -->
                          <v-col cols="2">
                            <v-icon color="red" class="pt-1" @click="removeVariantDetails(idx)">
                                mdi-minus-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" class="font-weight-bold body-1 pl-4">Total Pesentase</v-col>
                          <v-col cols="3" class="font-weight-bold body-1 pl-4">{{totalPercentage}}%</v-col>
                          <v-col cols="3"></v-col>
                          <v-col cols="2" class="">
                            <v-btn color="primary" outlined text @click="addVariantDetails()">Add</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="body-1 text-center">Anda akan menghapus variant ini?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDuplicate" max-width="400px">
                <v-card>
                    <v-card-title class="body-1 text-center">Anda akan menduplikasi resep variant ini <br>ke produk yang sama?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDuplicate=false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="duplicateVariantDetailsConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.size`]="{ item }">
              {{item.size}} ml
            </template>
            <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon small class="mr-2" @click="duplicateVariantDetails(item)">
                mdi-content-copy
            </v-icon> -->
            <v-icon small class="mr-2" @click="editLnb(item)" v-if="$store.state.currentPagePriv.update">
                mdi-bottle-tonic-outline
            </v-icon>
            <v-icon small class="mr-2" @click="editItem(item)" v-if="$store.state.currentPagePriv.update">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" v-if="$store.state.currentPagePriv.delete">
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
            </template>
        </v-data-table>
        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        
        <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
        <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
        <v-dialog v-model="lnbdialog" v-if="lnbdialog" max-width="800px" scrollable persistent>
          <v-card>
              <v-card-title>
              <span class="text-h5">Label & Botol</span>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation class="compact-form">
                  <v-row>
                    <v-col cols="4">Bahan Baku</v-col>
                    <v-col cols="3">Quantity</v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                  <v-row v-for="(obj, idx) in editedItem.details" :key="idx">
                    <v-col cols="4">
                      <v-autocomplete filled rounded dense :items="lnb" item-text="description" item-value="code" v v-model="obj.material_code" hide-details="auto" @change="fillUomLnb(obj)" autocomplete="off" :rules="reqRule" auto-select-first></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field v-model="obj.quantity" filled rounded dense hide-details="auto" :rules="reqRule" :suffix="obj.uom"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-icon color="red" class="pt-1" @click="removeVariantDetails(idx)">
                          mdi-minus-circle-outline
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10"></v-col>
                    <v-col cols="2" class="">
                      <v-btn color="primary" outlined text @click="addVariantBottles()">Add</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="lnbdialog = false">
                  Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveLnb">
                  Save
              </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>

  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Nama Variant', value: 'name' },
        { text: 'Initial', value: 'initial' },
        { text: 'Volume', value: 'size' },
        { text: 'Actual Volume', value: 'actual_volume' },
        { text: 'Stock on Hand', value: 'qty_stock' },
        { text: 'Min Stock', value: 'qty_min' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        details: []
      },
      defaultItem: {
      },
      search: '',
      rawmaterials: [],
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true,
      bottleSize: [],
      onProgress: false,
      saved: false,
      saveError: false,
      dialogDuplicate: false,
      machines: null,
      lnbdialog: false,
      lnb: []
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Variant' : 'Edit Variant'
      },
      roles () {
        return this.$store.state.role.objs
      },
      uom () {
        return this.$store.state.rawmaterial.uom
      },
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      totalPercentage() {
        return (this.editedItem.details.reduce((n, {quantity}) => n + Number(quantity), 0)).toFixed(2)
      },
      profile () {
        return this.$store.state.profile
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      uom () {
        return this.$store.state.rawmaterial.uom
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.rawmaterials = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
        this.lnb = this.rawmaterials.filter(el => {return el.category_code=='LNB'})
        const variantJuices = (await this.$store.dispatch('getObjs', {apidomain:'variantJuiceHeader', parameter:''})).data.data
        variantJuices.forEach(el => {
          this.rawmaterials.push({
            code: el.code,
            description: el.desc,
            uom: 'vj'
          })
        });
        console.log(variantJuices, this.rawmaterials)
        let response = await this.$store.dispatch('variant/getObjs')
        if (response.data.status=='success') this.items = response.data.data
        this.bottleSize = this.miscCodes.filter(element => {
          return element.type=='Botol'
        })
        this.bottleSize.sort((a, b) => parseFloat(a.code) - parseFloat(b.code));
        this.machines = this.miscCodes.filter(element => {
          return element.type=='Mesin'
        })
        this.machines.sort((a, b) => parseFloat(a.code) - parseFloat(b.code));

      // console.log(response)
      },

      async refreshData() {
        let response = await this.$store.dispatch('variant/getObjs')
        if (response.data.status=='success') this.items = response.data.data        
      },

      addNewItem() {
        this.dialog = true
        this.addVariantDetails()
      },

      fillUom(obj) {
        obj.uom = (this.rawmaterials.find(element=> {
          return element.code == obj.raw_material_code
        })).uom
      },

      fillUomLnb(obj) {
        obj.uom = (this.lnb.find(element=> {
          return element.code == obj.material_code
        })).uom
      },

      addVariantDetails() {
        let details = {raw_material_code: '', quantity: '', uom: ''}
        this.editedItem.details.push(details)
      },

      addVariantBottles() {
        let details = {variant_code: this.editedItem.code, material_code: '', quantity: '', uom: ''}
        this.editedItem.details.push(details)
      },

      removeVariantDetails(idx) {
        console.log(idx)
        this.editedItem.details.splice(idx, 1)
        console.log(this.editedItem.details, idx)
      },
      
      getCategoryName(code) {
        return this.categories.find(element => {
          return element.code == code
        }).category_name;
      },

      getSupplierName(code) {
        return this.suppliers.find(element => {
          return element.code == code
        }).supplier_name;
      },

      async editItem (item) {
        this.onProgress = true
        const vItem = await this.$store.dispatch('variant/getObj', item.code)
        console.log(vItem)
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, vItem)
        this.dialog = true
        this.onProgress = false
      },

      async editLnb (item) {
        this.onProgress = true
        this.editedIndex = this.items.indexOf(item)
        let vItem = item
        const vDetails = (await this.$store.dispatch('getObjs', {apidomain:'variantBottles/', parameter:item.code})).data.data
        this.$set(vItem, 'details', vDetails)
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, vItem)
        this.lnbdialog = true
        this.onProgress = false
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async duplicateVariantDetails(item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDuplicate = true
      },

      async duplicateVariantDetailsConfirm() {
        this.dialogDuplicate = false
        this.onProgress = true
        const item = this.editedItem
        const docs = await this.$store.dispatch('variant/duplicateVariantDetails', {variant_name: item.name, variant_code: item.code})
        this.onProgress = false
        if (docs) return this.saved = true
        else return this.saveError = true
      },

      async deleteItemConfirm () {
        let url = this.$store.state.config.apiHost + "variants/" + this.editedItem.code
        let response = await this.$axios.delete(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        if (response.data.status=='success') this.items.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({details:[]}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        await this.$refs.form.validate();
        if (!this.valid) return;
        const payload = this.cleanObj(this.editedItem)
        console.log('payload', payload)
        if (this.editedIndex > -1) {
          let url = this.$store.state.config.apiHost + "variants/" + this.editedItem.code
          let response = await this.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          if (response.data.status=='success') Object.assign(this.items[this.editedIndex], this.editedItem)
          this.close()          
        } else {
          try {
            let url = this.$store.state.config.apiHost + "variants"
            let response = await this.$axios.post(url, JSON.stringify(this.editedItem), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
            if (response.data.status=='success') this.items.push(this.editedItem)
            this.close()
          }
          catch (err) {
            const keyword = 'duplicate key'
            if (err.response.data.message.search(keyword) > -1){
              return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Duplicate Key! Kode sudah terpakai."})
            }
            else {
              return this.$store.commit('setSnackbar', {saveError: true, errorMessage: err.response.data.message})
            }
          }

        }
      },

      async saveLnb () {
        await this.$refs.form.validate();
        if (!this.valid) return;
        const payload = {bottles: this.editedItem.details}
        console.log('payload', payload)
        try {
          let url = this.$store.state.config.apiHost + "variantBottles"
          await this.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          // if (response.data.status=='success') this.items.push(this.editedItem)
          this.lnbdialog = false
        }
        catch (err) {
          const keyword = 'duplicate key'
          if (err.response.data.message.search(keyword) > -1){
            return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Duplicate Key! Kode sudah terpakai."})
          }
          else {
            return this.$store.commit('setSnackbar', {saveError: true, errorMessage: err.response.data.message})
          }
        }

      },
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>